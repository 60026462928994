import React from "react";
import "./contato.css"

import facebook from "../../images/facebook-contatos.png"
import linkedin from "../../images/linkedinbranco.svg"
import instagram from "../../images/instagrambranco.svg"

import emailjs from '@emailjs/browser';
import { useState } from 'react';
//npm install @emailjs/browser --save

export default function Contatos() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    function sendEmail(e){
        e.preventDefault();

        if(name === '' || email === '' || message === ''){
            alert("Preencha os campos para enviar a mensagem");
            return;
        }

        const parametros = {
            from_name: name,
            message: message,
            email: email
        }

        emailjs.send("service_ng3tnwd", "template_m97c68g", parametros, "J7NRXiblMH2fjFlYo")
        .then((response) => {alert("Email enviado", response.status, response.text) 
        setName('')
        setEmail('')
        setMessage('')
        }, (err) => {alert("Erro:", err)})
    }

    return (
        <section id="contatos">
            <head>
            <link href='https://fonts.googleapis.com/css?family=Montserrat:400,700' rel='stylesheet' type='text/css'/>
            </head>
            <div className="title-contatos">
                <h3>Contatos</h3>
                <p>Sigam-nos nas redes sociais!</p>

                <div className="container-icons-contatos">
                    <div className="icons-contatos">
                        <img src={linkedin} alt="logomarca Linkedin" />
                        <button className="buttons-contato"><a href="https://www.linkedin.com/company/vertuno/" target="_blank" rel="noopener noreferrer">Linkedin</a></button>
                    </div>
                    <div className="icons-contatos">
                        <img src={instagram} alt="logomarca Instagram" />
                        <button className="buttons-contato"><a href="https://instagram.com/vertuno.usp?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noopener noreferrer">Instagram</a></button>
                    </div>
                    <div className="icons-contatos">
                        <img src={facebook} alt="logomarca Facebook" />
                        <button className="buttons-contato"><a href="https://www.facebook.com/VertunoGPP?mibextid=b06tZ0" target="_blank" rel="noopener noreferrer">Facebook</a></button>
                    </div>
                </div>
            </div>
            <div className="form-contatos">
                <h4>Se interessou pelo nosso projeto?</h4>
                <div className="form-contatos-form">
                    <form id='formularioContato' onSubmit={sendEmail}>
                        <label for="campoNome" id='labelNomeContato'>Seu nome:</label>
                        <input type="text" id="campoNome" onChange={(e) => setName(e.target.value)} value={name}/>

                        <div id='doisCamposContato'>
                            <div className='campoFormContato' id='divEmailContato'>
                                <label for="lemail">Seu e-mail:</label>
                                <input type="text" id="lemail" onChange={(e) => setEmail(e.target.value)} value={email}/>
                            </div>
                        </div>

                        <label for="lmensagem">Mensagem:</label>
                        <textarea id="lmensagem" onChange={(e) => setMessage(e.target.value)} value={message}/>

                        <input type="submit" name="Enviar mensagem" id="botaoEnviarContato"/>
                    </form>
                </div>
            </div>
        </section>
    );
}